import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import TextLoop from "react-text-loop"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import PriceIncrease from "../components/modals/priceIncrease"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCreditCard,
  faGift,
  faHandHoldingHeart,
  faPaperPlane,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons"

const SESSION_MODAL_KEY = "tawalap-session-ashur-info-modal"

const IndexPage = () => {
  const [modalShow, setModalShow] = React.useState(false)

  React.useEffect(() => {
    // check session if already dismissed ignore showing it
    if (!sessionStorage.getItem(SESSION_MODAL_KEY) && !isItAfterThe10th()) {
      setModalShow(true)
    }
  }, [])

  const hideModal = () => {
    setModalShow(false)
    sessionStorage.setItem(SESSION_MODAL_KEY, true)
  }

  const isItAfterThe10th = () => {
    // check date only show before 10th
    return new Date("12/10/2023").getTime() < new Date().getTime()
  }

  return (
    <Layout>
      <Seo title="Home" />
      <section className="py-5 text-center container mb-5">
        <div className="row py-lg-5 mt-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <StaticImage
              src="../images/logo.png"
              width={300}
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="tawalap logo"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row text-white">
          <div className="col" style={{ minHeight: "130px" }}>
            <h2 className="text-uppercase">
              <TextLoop springConfig={{ stiffness: 100, damping: 10 }}>
                <span className="h1">Heritage</span>
                <span className="aramaic h1">ܝܪܝܼܬܘܼܬܵܐ</span>
                <span className="h1">Luxury</span>
                <span className="aramaic h1">ܬܲܦܢܝܼܩܵܐ</span>
              </TextLoop>
            </h2>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-charcol-black">
        <div className="container">
          <Link
            to="/products"
            className="nav-link pb-5"
            style={{ fontSize: 14 }}
            data-cy='latest-collection-link'
          >
            <div className="row mb-5">
              <div className="col-12 text-white text-center">
                <p className="h4 mt-5 mb-3 text-uppercase">
                  Discover our latest collection
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-3 text-center">
                <StaticImage
                  src="../images/home_page/assyrian_flag.png"
                  width={"auto"}
                  quality={100}
                  formats={["AUTO", "WEBP"]}
                  alt="Assyrian flag pendant"
                  className="img-fluid"
                />
              </div>
              <div className="col-3 text-center">
                <StaticImage
                  src="../images/home_page/rosette.png"
                  width={"auto"}
                  quality={100}
                  formats={["AUTO", "WEBP"]}
                  alt="Rosette pendant"
                  className="img-fluid"
                />
              </div>
              <div className="col-3 text-center">
                <StaticImage
                  src="../images/home_page/tree_of_life.png"
                  width={"auto"}
                  quality={100}
                  formats={["AUTO", "WEBP"]}
                  alt="Tree of Life pendant"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-white text-center">
                <p className="minimal-body mb-0">Discover the collection now</p>
              </div>
              <div className="col-lg-6 text-white mt-5">
                <p className="minimal-body">
                  A collectively inspired collection from our beloved people’s
                  input; the WOW collection is comprised of three very special
                  symbols that you can wear and be proud of.
                  <br />
                  <br />
                  They are the powerful <i>Assyrian Flag</i>, the <i>Rosette</i>{" "}
                  so famously worn by ancient Kings and Queens & the beautiful{" "}
                  <i>Tree of Life</i>.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="row bg-wow-collection"></div>
      </section>
      <section className="container-fluid bg-radial-center pb-5">
        <div className="container">
          <Link
            to="/products/ashur-pendant"
            className="nav-link pb-5"
            style={{ fontSize: 14 }}
            data-cy='bestselling-link'
          >
            <div className="row mb-5">
              <div className="col-12 text-center">
                <p className="h4 mt-5 mb-3 text-uppercase">
                  Bestselling pendant
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-6 col-md-4 mt-md-3 mt-5 me-md-4">
                <StaticImage
                  src="../images/ashur_pendant/insta-ashur-1_1.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP"]}
                  alt="Model wearing small ashur pendant"
                  className="img-fluid w-100 rounded"
                />
              </div>
              <div className="col-6 col-md-4 mt-md-5 mt-3">
                <StaticImage
                  src="../images/home_page/j_esho_ashur_pendant.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP"]}
                  alt="J.Esho wearing the large ashur pendant"
                  className="img-fluid w-100 rounded"
                />
              </div>
            </div>
            <div className="col-12 text-center mt-5">
              <p className="minimal-body mb-0">Discover the Ashur pendant</p>
            </div>
          </Link>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <p className="minimal-body">
                Our flagship Ashur pendant is by far the most attractive option
                for our beloved customers, a powerful symbol containing all of
                our heritage into one jewelry piece with our Nation's name to
                wear and represent our beautiful and rich culture.
              </p>
              <p className="minimal-body">
                It is the go to pendant jewelry for many of our amazing Artists.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-charcol-black pb-5">
        <div className="row pb-4">
          <div className="col-12 text-white text-center">
            <p className="h4 mt-5 mb-3 text-uppercase">At Your Service</p>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-start text-white my-5">
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faShippingFast}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Enjoy complimentary worldwide shipping.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon icon={faGift} className="text-light fa-2x" />
              <p className="minimal-body mt-3">
                The perfect option for any occasion.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faCreditCard}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">Secure checkout options.</p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faHandHoldingHeart}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Peace of mind, with clean and ethically sourced products.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faPaperPlane}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Always happy to assist via{" "}
                <a
                  href="mailto:shlama@tawalap.com"
                  className="text-decoration-none text-white"
                >
                  email
                </a>.
              </p>
            </div>
          </div>
        </div>
      </section>

      <PriceIncrease
        show={modalShow}
        onHide={hideModal}
        showctabutton={"true"}
      />
    </Layout>
  )
}
export default IndexPage
